import React, { createContext, useContext, useState, Dispatch, SetStateAction, useEffect } from "react";
import config from "../config";
import { OktaAuth } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { getUserInfo } from "../api/AuthApi";
import { RoleConstants } from "../constants/RoleConstants";
import { User, UserPreference } from "../types/SettingsTypes";
import { datadogRum } from "@datadog/browser-rum";

interface UserInfo extends User {
  accessToken: { accessToken: string };
  idToken: { idToken: string };
  userName: string;
  userFullName: string;
  email: string;
  orgId: number;
  orgName: string;
  orgShortName: string;
  userId: number;
  role: string;
  permissions: string[];
  operations: string[];
  positions: string[];
  userPreference: UserPreference;
}

type UserContext = {
  userInfo: UserInfo;
  loggedIn: boolean;
  onLogin: (data: UserInfo) => void;
  logout: () => void;
  permissionError: boolean;
  loginFunction: SetStateAction<void>;
  setPermissionError: Dispatch<SetStateAction<boolean>>;
  setLoginFunction: Dispatch<SetStateAction<void>>;
  userId: () => number;
  isAdmin: () => boolean;
  isBasic: () => boolean;
  isScheduler: () => boolean;
  isSettingsEditor: () => boolean;
  setUserPreference: Dispatch<SetStateAction<Partial<UserPreference>>>;
};

const UserContext = createContext<UserContext>({
  userInfo: null,
  loggedIn: false,
  onLogin: null,
  logout: null,
  permissionError: false,
  loginFunction: null,
  setPermissionError: null,
  setLoginFunction: null,
  userId: null,
  isAdmin: null,
  isBasic: null,
  isScheduler: null,
  isSettingsEditor: null,
  setUserPreference: null,
});

const initialState = {
  userInfo: {
    accessToken: { accessToken: "" },
    idToken: { idToken: "" },
    userName: "",
    userFullName: "",
    email: "",
    orgId: 0,
    orgName: "",
    orgShortName: "",
    permissions: ["NONE"],
    userId: 0,
    role: "",
    operations: [] as string[],
    positions: [] as string[],
    userPreference: { sportId: 1 },
  },
  loggedIn: false,
};

const oktaAuth = new OktaAuth(config.oidc);

const UserProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [userState, setUserState] = useState({
    userInfo: initialState.userInfo,
  });
  const [loggedIn, setLoggedIn] = useState<boolean>(initialState.loggedIn);
  const [permissionError, setPermissionError] = useState<boolean>(false);
  const [loginFunction, setLoginFunction] = useState(null);
  const { authState } = useOktaAuth();
  localStorage.setItem("sportId", initialState.userInfo?.userPreference?.sportId?.toString());

  const userId = (): number => {
    return userState.userInfo.userId;
  };

  const isAdmin = (): boolean => userState.userInfo.role === RoleConstants.SUPER_ADMIN;
  const isBasic = (): boolean => userState.userInfo.role === RoleConstants.BASIC;
  const isScheduler = (): boolean => userState.userInfo.role === RoleConstants.SCHEDULER;
  const isSettingsEditor = (): boolean => userState.userInfo.role === RoleConstants.SETTINGS_EDITOR;

  const setUserPreference = (userPreference: UserPreference) => {
    localStorage.setItem("sportId", userPreference?.sportId?.toString());
    setUserState({
      ...userState,
      userInfo: {
        ...userState.userInfo,
        userPreference: userPreference,
      },
    });
  };

  const onLogin = async (userData: UserInfo): Promise<void> => {
    setLoggedIn(true);
    //Fill in with application-specific user info call
    const userInfoData = await getUserInfo();
    if (userInfoData) {
      userData = { ...userData, ...userInfoData };
      setUserState({
        userInfo: userData,
      });
      setLoggedIn(true);
      localStorage.setItem("sportId", userData?.userPreference?.sportId?.toString());
    }
  };

  const logout = (): void => {
    localStorage.clear();
    setUserState({ userInfo: initialState.userInfo });
    setLoggedIn(false);
  };

  useEffect(() => {
    if (authState?.isAuthenticated) {
      datadogRum.setUser({
        id: authState.idToken?.claims.email,
        email: authState.idToken?.claims.email,
        name: authState.idToken?.claims.name,
      });
      // when the user becomes authenticated, call onLogin() to populate AuthContext's user info
      onLogin({ ...initialState.userInfo, accessToken: authState.accessToken, idToken: authState.idToken });
    }
  }, [authState]);

  return (
    <UserContext.Provider
      value={{
        userInfo: userState.userInfo,
        loggedIn,
        onLogin,
        logout,
        permissionError,
        setPermissionError,
        loginFunction,
        setLoginFunction,
        userId,
        isAdmin,
        isBasic,
        isScheduler,
        isSettingsEditor,
        setUserPreference,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = (): UserContext => {
  const userContext = useContext<UserContext>(UserContext);
  if (userContext === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return userContext;
};

export { UserContext, UserProvider, oktaAuth, useUser, UserInfo };
