import BigNumber from "bignumber.js";
import clone from "rfdc";
import { AnyObject, BooleanMap } from "../../types/Objects";

export function deepClone<T>(toClone: T) {
  return clone({
    proto: true,
    circles: false,
    constructorHandlers: [
      //date time will not work since it doesnt have a constructor
      // [DateTime, (dt: DateTime) => DateTime.fromISO(dt.toISO())],
      [BigNumber, (bn: BigNumber) => new BigNumber(bn.toString())],
    ],
  })(toClone);
}

//ONLY WORKS FOR 1:1 MAPPINGS OF KEY:VALUE
export const getKeyByValue = (object: AnyObject, value: any): any => {
  //@ts-ignore @eslint-ignore
  return Object.keys(object).find((key) => object[key] === value);
};

export const isFunction = (value: any): boolean => checkTypeOf(value, "function");

export const isObject = (value: any): boolean => checkTypeOf(value, "object");

export const checkTypeOf = (value: any, type: string): boolean => typeof value === type;

export const areObjectsEqual = <T>(obj1?: T, obj2?: T): boolean => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const removeNullFields = (obj: AnyObject) =>
  Object.entries(obj)
    .filter(([_, v]) => v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

export const checkAllFalseAndSetFirstTrue = (map: BooleanMap): BooleanMap => {
  const keys = Object.keys(map);
  if (keys.length === 0) {
    return map;
  }
  const values = Object.values(map);
  const allFalse = values.every((value) => !value);
  if (allFalse) {
    map[keys[0]] = true;
  }
  return map;
};

export function deepMerge<T extends AnyObject>(...sources: AnyObject[]): T {
  const result: AnyObject = {};

  if (!sources.length) return result as T;

  for (const source of sources) {
    if (isObject(source)) {
      for (const key in source) {
        if (isObject(source[key])) {
          if (!result[key]) result[key] = {};
          result[key] = deepMerge(result[key], source[key]);
        } else {
          result[key] = source[key];
        }
      }
    }
  }

  return result as T;
}
