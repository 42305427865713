import { format, parse } from "date-fns/esm";
import React, { createContext, Dispatch, SetStateAction, useContext, useMemo, useEffect, useState } from "react";
import { BaseStaticOptions, TopNavDTO } from "../types/ReviewDetailsTypes";
import { GameDataDTO } from "../types/DailyAssignmentTypes";
import { getReviewStaticOptions } from "../api/review/ReviewApi";

type ScheduleGamesContext = {
  schedule: GameDataDTO[];
  setSchedule: Dispatch<SetStateAction<GameDataDTO[]>>;
  scheduleDate: Date;
  setScheduleDate: Dispatch<SetStateAction<Date>>;
  selectedGame: GameDataDTO;
  setSelectedGame: Dispatch<SetStateAction<GameDataDTO>>;
  scheduleDateString: string;
  weekStartDate: Date;
  weekEndDate: Date;
  weekStartDateString: string;
  weekEndDateString: string;
  //store the reviews for the day in context
  reviews: TopNavDTO[];
  setReviews: Dispatch<SetStateAction<TopNavDTO[]>>;
  specialLinks: TopNavDTO[];
  setSpecialLinks: Dispatch<SetStateAction<TopNavDTO[]>>;
  getTeamAbbrv: (homeOrAway: string) => string;
  //store dropdown static options
  dropdownOptions: BaseStaticOptions;
};

const ScheduleGamesContext = createContext<ScheduleGamesContext>({
  schedule: null,
  setSchedule: null,
  scheduleDate: null,
  setScheduleDate: null,
  scheduleDateString: null,
  weekStartDate: null,
  weekEndDate: null,
  weekStartDateString: null,
  weekEndDateString: null,
  selectedGame: null,
  setSelectedGame: null,
  reviews: null,
  setReviews: null,
  specialLinks: null,
  setSpecialLinks: null,
  getTeamAbbrv: null,
  dropdownOptions: null,
});

type State = {
  schedule: GameDataDTO[];
  scheduleDate: Date;
  selectedGame: GameDataDTO;
  reviews: TopNavDTO[];
  specialLinks: TopNavDTO[];
  dropdownOptions: BaseStaticOptions;
};

const DATE_FORMAT = "yyyy-MM-dd";
const getDateString = (date: Date): string => format(date, DATE_FORMAT);

const ScheduleGamesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const localStorageDate: string = localStorage.getItem("scheduleDate");

  const initialState: State = {
    schedule: [],
    scheduleDate: new Date(),
    selectedGame: null,
    reviews: [],
    specialLinks: [],
    dropdownOptions: null,
  };

  if (localStorageDate) {
    initialState.scheduleDate = parse(localStorageDate, DATE_FORMAT, new Date());
  }

  const [schedule, setSchedule] = useState<GameDataDTO[]>(initialState.schedule);
  const [scheduleDate, setScheduleDateState] = useState<Date>(initialState.scheduleDate);
  const [selectedGame, setSelectedGameState] = useState<GameDataDTO>(initialState.selectedGame);
  const [scheduleDateString, setScheduleDateString] = useState<string>(getDateString(initialState.scheduleDate));
  const [reviews, setReviews] = useState<TopNavDTO[]>(initialState.reviews);
  const [specialLinks, setSpecialLinks] = useState<TopNavDTO[]>(initialState.specialLinks);
  const [dropdownOptions, setDropdownOptions] = useState<BaseStaticOptions>(initialState.dropdownOptions);

  useEffect(() => {
    getReviewStaticOptions().then(({ playLocations, playTypes, innings }) => {
      setDropdownOptions({ playLocations, playTypes, innings });
    });
  }, []);

  const weekStartDate = useMemo(() => {
    if (!scheduleDate) {
      return null;
    }
    const day = scheduleDate.getDay();
    const startDate = new Date(scheduleDate);
    const offset = day == 0 ? -6 : 1; // Use Monday as start date instead of Sunday
    startDate.setDate(scheduleDate.getDate() - day + offset);
    return startDate;
  }, [scheduleDate]);

  const weekEndDate = useMemo(() => {
    if (!weekStartDate) {
      return null;
    }
    const endDate = new Date(weekStartDate);
    endDate.setDate(weekStartDate.getDate() + 6);
    return endDate;
  }, [weekStartDate]);

  const weekStartDateString = useMemo(() => {
    if (!weekStartDate) {
      return null;
    }
    return getDateString(weekStartDate);
  }, [weekStartDate]);

  const weekEndDateString = useMemo(() => {
    if (!weekEndDate) {
      return null;
    }
    return getDateString(weekEndDate);
  }, [weekEndDate]);

  const setScheduleDate = (date: Date): void => {
    if (!date) return;
    setScheduleDateState(date);
    const dateStr: string = getDateString(date);
    setScheduleDateString(dateStr);
    window.localStorage.setItem("scheduleDate", dateStr);
  };

  const setSelectedGame = (game: GameDataDTO): void => {
    setSelectedGameState(game);
  };

  const getTeamAbbrv = (homeOrAway: string): string => {
    return selectedGame?.[homeOrAway]?.abbreviation || "TBD";
  };

  return (
    <ScheduleGamesContext.Provider
      value={{
        schedule,
        setSchedule,
        scheduleDate,
        setScheduleDate,
        selectedGame,
        setSelectedGame,
        scheduleDateString,
        weekStartDate,
        weekEndDate,
        weekStartDateString,
        weekEndDateString,
        reviews,
        setReviews,
        specialLinks,
        setSpecialLinks,
        getTeamAbbrv,
        dropdownOptions,
      }}
    >
      {children}
    </ScheduleGamesContext.Provider>
  );
};

const useScheduleGames = (): ScheduleGamesContext => {
  const scheduleGamesContext = useContext<ScheduleGamesContext>(ScheduleGamesContext);
  if (scheduleGamesContext === undefined) {
    throw new Error(`useScheduleGames must be used within a ScheduleGamesProvider`);
  }
  return scheduleGamesContext;
};

export { ScheduleGamesContext, ScheduleGamesProvider, useScheduleGames };
